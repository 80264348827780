import { combineReducers } from "redux";
import paramsReducer, { navRecuer } from "../views/components/api/paramsAPI";
import authenticationReducer from "../views/components/api/Auth/AuthSlice";
import appUserReducer from "../views/components/api/UsersSlice";
import Assurance from "../views/components/api/AssuranceSlice";

const reducers = combineReducers({
  navig: navRecuer,
  authentication: authenticationReducer,
  appUsers: appUserReducer,
  assurance: Assurance,
});

export default reducers;
