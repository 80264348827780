import React from "react";
import { Route, Redirect } from "react-router-dom";
import {getRole, getToken} from "./jwt/config";

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {

  const userRole = getRole();// Assurez-vous de stocker le rôle lors de la connexion
  const isAuthenticated = localStorage.getItem("token") !== null;

  return (
    <Route
      {...rest}
      render={(props) => {
        console.log('role actuele est :: ',roles);
        console.log('role user  est :: ',userRole);
        console.log('token Actuel  est :: ',isAuthenticated);
        if (!isAuthenticated) {
          // Rediriger si l'utilisateur n'est pas authentifié
          return <Redirect to="/login" />;
        }
        if (roles && !roles.includes(userRole)) {
          // Rediriger si l'utilisateur n'a pas le rôle approprié
          return <Redirect to="/dashboard" />;
        }

        // Afficher le composant s'il est authentifié et a le bon rôle
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
