import { differenceInYears, parse } from "date-fns";
export const invalide = {
  display: "block",
  width: "100%",
  marginTop: "0.25rem",
  fontSize: "80%",
  color: "#e55353",
};

export const isInvalide = {
  borderColor: "#dc3545",
};

export const genre = [
  { id: 1, libelle: "Masculin" },
  { id: 2, libelle: "Féminin" },
];

export const getGenreLabel = (id) => {
  return genre.find((e) => e.id == +id).libelle;
};

export const typeNumeroRecherche = [
  { id: 1, libelle: "N° PPR" },
  { id: 2, libelle: "N° Pension" },
];

export const typeEnfants = [
  { id: 1, libelle: "Biologique" },
  { id: 2, libelle: "Adoptif" },
];

export function formatDate(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
}

export const dateString = (date) => date.toISOString().replace(/T/, ' ').replace(/\..+/, '');


export function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function getLabelFromId(id, list) {
  return list.find((e) => e.id === id).libelle;
}

export function calculateAge(dob) {
  const date = parse(dob, "yyyy-MM-dd", new Date());
  console.log("the date is ===> ", date);
  const age = differenceInYears(new Date(), date);
  return age;
}
