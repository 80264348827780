import React, { useEffect, useState, createRef, useRef } from "react";
import { withFormik, Form, Field, ErrorMessage } from "formik";
import {
  CFormGroup,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CSelect,
  CInput,
  CInputCheckbox,
  CSwitch,
  CTextarea,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CBadge,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import Select from "react-select";
import { isInvalide } from "../utils";

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

const TrInput = ({
  name,
  libelle,
  icon,
  error,
  inputType,
  isDisabled,
  placeholder = "",
  useBlur,
  handleBlur,
  customComponent,
  labelInLine,
}) => {
  return (
    <CFormGroup>
      {labelInLine != true && (
        <CLabel htmlFor="prependedInput">{libelle}</CLabel>
      )}

      <div className="controls">
        <CInputGroup className="input-prepend">
          <CInputGroupPrepend>
            <CInputGroupText>
              <CIcon name={icon} />
            </CInputGroupText>
          </CInputGroupPrepend>
          <Field
            id={name}
            size="16"
            type={inputType}
            name={name}
            placeholder={placeholder}
            onBlur={(e) => {
              if (useBlur != null) handleBlur(e.currentTarget.value);
            }}
            disabled={isDisabled}
            component={customComponent}
            style={error ? { borderColor: "#dc3545" } : {}}
          />
        </CInputGroup>
      </div>
    </CFormGroup>
  );
};

const TrSelect = ({
  name,
  libelle,
  icon,
  error,
  selected,
  valueWithLabel,
  inputType,
  customComponent,
  listOptions,
  placeholder,
  onChange,
  isDisabled,
  labelInLine,
}) => {
  return (
    <CFormGroup>
      {labelInLine != true && (
        <CLabel htmlFor="prependedInput">{libelle}</CLabel>
      )}
      <div className="controls">
        <CInputGroup className="input-prepend">
          <CInputGroupPrepend>
            <CInputGroupText>
              <CIcon name={icon} />
            </CInputGroupText>
          </CInputGroupPrepend>
          <Field
            id={name}
            size="16"
            type={inputType}
            name={name}
            disabled={isDisabled}
            valuewithlabel={valueWithLabel}
            optionselected={selected}
            options={listOptions}
            onChange={onChange}
            placeholder={placeholder}
            component={customComponent}
            style={error ? { borderColor: "#dc3545" } : {}}
          />
        </CInputGroup>
      </div>
    </CFormGroup>
  );
};

const TrSelectObject = ({
  name,
  libelle,
  icon,
  error,
  selected,
  valueWithLabel,
  inputType,
  customComponent,
  listOptions,
  placeholder,
  onChange,
  isDisabled,
  labelInLine,
}) => {
  return (
    <CFormGroup>
      {labelInLine != true && (
        <CLabel htmlFor="prependedInput">{libelle}</CLabel>
      )}
      <div className="controls">
        <CInputGroup className="input-prepend">
          <CInputGroupPrepend>
            <CInputGroupText>
              <CIcon name={icon} />
            </CInputGroupText>
          </CInputGroupPrepend>
          <Field
            id={name}
            size="16"
            type={inputType}
            name={name}
            disabled={isDisabled}
            valuewithlabel={valueWithLabel}
            optionselected={selected}
            options={listOptions}
            onChange={onChange}
            placeholder={placeholder}
            component={customComponent}
            style={error ? { borderColor: "#dc3545" } : {}}
          />
        </CInputGroup>
        {/* <p className="help-block">Here's some help text</p> */}
      </div>
    </CFormGroup>
  );
};

const MyInput = ({ field, form, ...props }) => {
  return <CInput {...field} {...props} />;
};

const MyTextArea = ({ field, form, ...props }) => {
  return <CTextarea {...field} {...props} />;
};

const CheckBox = ({ field, form, ...props }) => {
  return (
    <CFormGroup variant="checkbox" className="checkbox">
      <CInputCheckbox id="checkbox1" {...props} {...field} />
      <CLabel
        variant="checkbox"
        className="form-check-label"
        htmlFor="checkbox1"
      >
        {props.displayvalue}
      </CLabel>
    </CFormGroup>
  );
};

const SwitchInput = ({ field, form, ...props }) => {
  return (
    <CFormGroup>
      <CLabel htmlFor="prependedInput">{props.libelle}</CLabel>
      <div className="controls">
        <CSwitch
          {...field}
          {...props}
          className={"mx-1"}
          // shape={"pill"}
          color={"primary"}
          // labelOn={"\u2713"}
          // labelOff={"\u2715"}
        />
      </div>
    </CFormGroup>
  );
};

const SelectInput = React.forwardRef(
  ({ onChange, onKeyPress, field, form, ...props }, ref) => {
    return (
      <CSelect
        {...field}
        {...props}
        ref={ref}
        onChange={(changeEvent) => {
          form.setFieldValue(field.name, changeEvent.target.value);

          onChange(changeEvent.target.value);
        }}
      >
        <option value="-1">{props.placeholder}</option>
        {props.options != null &&
          (props.valuewithlabel == "true"
            ? props.options.map((el) => (
                <option key={el.id} value={el.id + "-" + el.libelle}>
                  {el.libelle}
                </option>
              ))
            : props.options.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.libelle}
                </option>
              )))}
      </CSelect>
    );
  }
);

const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};

const SearchableInput = React.forwardRef(
  ({ onChange, onKeyPress, field, form, ...props }, ref) => {
    let values = props.options.map((e) => ({ value: e.id, label: e.libelle }));
    return (
      <Select
        styles={styles}
        {...field}
        {...props}
        ref={ref}
        value={props.optionselected && props.optionselected}
        defaultValue={props.options[1]}
        isClearable={true}
        isSearchable={true}
        name="color"
        options={values}
        onChange={(changeEvent) => {
          // form.setFieldValue(field.name, changeEvent);
          onChange(changeEvent);
        }}
      />
    );
  }
);

const CustomModal = ({
  title,
  children,
  modal,
  setModal,
  callback,
  showBtn = true,
  okMessage,
  mSize = "",
  showCloseBtn = true,
}) => {
  return (
    <CModal show={modal} onClose={setModal} size={mSize}>
      <CModalHeader closeButton>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>{children}</CModalBody>
      <CModalFooter>
        {showBtn && (
          <CButton color="primary" onClick={() => callback(false)}>
            {okMessage}
          </CButton>
        )}
        {showCloseBtn && (
          <CButton color="secondary" onClick={() => setModal(false)}>
            Fermer
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
};

export {
  TrInput,
  TrSelect,
  TrSelectObject,
  MyInput,
  SelectInput,
  SwitchInput,
  CheckBox,
  MyTextArea,
  CustomModal,
  SearchableInput,
};
