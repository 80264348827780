import React from "react";

import { useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
const BaseTableParams = ({ data, columns }) => {
  useEffect(() => {}, [data]);

  const tableData = {
    columns: columns,
    data: data,
  };

  return (
    <>
      <DataTableExtensions exportHeaders={false} print={false} {...tableData}>
        <DataTable
          data={[...data]}
          columns={columns}
          pagination
          paginationComponentOptions={{ noRowsPerPage: true }}
          paginationPerPage={10}
          highlightOnHover
          noDataComponent={
            <p style={{ marginBottom: "2em" }}>
              <h6>Pas d'information à afficher</h6>
            </p>
          }
        />
      </DataTableExtensions>
    </>
  );
};

export default BaseTableParams;
