
import React, {useState, useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {ErrorMessage, Form, Formik, withFormik} from "formik";
import * as Yup from "yup";
import "src/views/components/adhStyle.css";
import {
  CButton,
  CCardBody,
  CTabs,
  CTabContent,
  CTabPane,
  CDataTable,
  CNavItem,
  CNavLink,
  CNav,
  CCollapse,
  CBadge,
  CCardHeader,
  CCard,
  CCol, CModal, CModalHeader, CModalBody, CFormGroup, CLabel, CModalFooter, CRow, CForm, CInput,
} from "@coreui/react";

import BaseTableParams from "../ParamsTables/BaseTableParams";
import CIcon from "@coreui/icons-react";
import {cilCheckAlt} from "@coreui/icons";
import {MyInput, SelectInput, TrInput, TrSelect} from "../../FormComponent";
import {genre, invalide} from "../../utils";
import {toast, ToastContainer} from "react-toastify";
import {getCommunesAPI, getMois, getProvincesAPI, getRegionsAPI} from "../api/paramsAPI";
import {
  attribueeBourseAPI,
  incompteteBourseAPI,
  listAnneeAPI,
  listDemandeAPI,
  refuserBourseAPI
} from "../api/BourceExcelenceSlice";
import {
  getFileSoutienByDemandeAndEnfant,
  getFileSoutienByDemandeAndType, incompteteSoutienAPI,
  listDemandeApi, listSoutienFilterAPI, listSoutientDemandeTraiteAPI,
  listTypeFichierSavedSoutienAPI, refuserSoutientAPI, traiteDemandeSoutienAPI, validationSoutientAPI
} from "../api/SoutienScolaireSlice";
import 'react-toastify/dist/ReactToastify.css';
import {
  getFileAssuranceByDemandeAndType,
  listAssuranceDemandeAPI,
  listTypeFichierAssuranceSavedAPI, validationAssuranceAPI
} from "../api/AssuranceSlice";
import {getFileByDemandeAndType} from "../api/EstivageSlice";
import {getRole} from "../../../jwt/config";


const validationSchema = Yup.object().shape({
  motifRefus: Yup.string()
    .nullable()
    .trim('Ce champs ne peut pas être vide')
    .required('Ce champs est requis'),
});

const Colonies = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const  listDemande = useSelector((state) => state.assurance.listeDemande);
  //const listeMois = useSelector((state) => state.params.listeMois);
  //const  listDemandeTraite = useSelector((state) => state.soutienScolaire.listDemandeTraite);
  const  listeTypeFichierSaved = useSelector((state) => state.assurance.listeTypeFichierSaved);
 // const listAnnee = useSelector((state) => state.bourseExcellence.listAnnee);
  const [modalRefus, setModalRefus] = useState(false);
  const [modalIncmplete, setModalIncmplete] = useState(false);
  const [active, setActive] = useState("correct");
  const [modalDetails, setModalDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selected, setSelected] = useState({
    motifRefus:"",
  });

  useEffect(() => {
    //dispatch(getRegionsAPI());
    //dispatch(listAnneeAPI());
    //dispatch(getMois());
    dispatch(listAssuranceDemandeAPI());
  }, []);




  const handleValidation = (id) => {
    dispatch(validationAssuranceAPI({ id })).then(() => {
      dispatch(listAssuranceDemandeAPI());
      //setModalDetails(false);
    }).catch(error => {
      toast.error('Une erreur est survenue .');
    });
  };
  const handleTraite = (id) => {
    dispatch(traiteDemandeSoutienAPI({ id })).then(() => {
      toast.info('La demande Traité avec succès !');
      setModalRefus(false);
      dispatch(listDemandeApi());
      dispatch(listSoutientDemandeTraiteAPI());
    }).catch(error => {
      toast.error('Une erreur est survenue lors de l\'acceptation de la demande.');
    });
  };


  const fields = [
    {
      key: "libelle",
      label: "Type de fichier",
    },

    {
      key: "action",
      label: "Fichier",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];


  const columns = useMemo(
    () => [
      {
        name: "N° Demande",
        selector: "idDemande",
        sortable: true,
      },
      {
        name: "Type Assurance",
        selector: "type",
        sortable: true,
      },
      {
        name: "bénéficiere",
        selector: "beneficiere",
        sortable: true,
      },
      {
        name: "Date Demande",
        selector:  "dateInscription",
        sortable: true,
      },
      {
        name: "Statut",
        selector: "libelleStatut",
        cell: (d) => (
          <>
            {d.statut ? (
              <CBadge color={getBadge(d.statut)}>
                {d.statut}
              </CBadge>
            ) : (
              <CBadge color={getBadge("Non_def")}>Non défini</CBadge>
            )}
          </>
        ),
        width: "100px",
        sortable: true,
      },
      {
        cell: (row) => (
          <CButton
            color="primary"
            variant="outline"
            shape="square"
            size="sm"
            style={{ width: 100 }}
            onClick={() => {
              setSelectedRow(row);
              setModalDetails(true);
              console.log('le role de user est :: ',getRole());
              dispatch(listTypeFichierAssuranceSavedAPI(row.idDemande))
            }}
          >
            Détails
            <CIcon name="cil-pencil" style={{ marginLeft: 10 }} />
          </CButton>
        ),
        ignoreRowClick: true,
        button: true,
        export: false,
      },
      /*{
        cell: (row) => (
          row.statut != 'Traite' ? (
            <CButton
              color="danger"
              variant="outline"
              shape="square"
              size="sm"
              style={{ width: 100,marginLeft:5 }}
              disabled={row.statut==='Accepte' || row.statut==='Rejet'}
              onClick={() => {
                setSelectedRow(row);
                setModalRefus(true);
              }}
            >
              Rejetée
              <CIcon name="cilX" style={{ marginLeft: 10 }} />
            </CButton>
          ) : null
        ),
        ignoreRowClick: true,
        button: true,
        export: false,
      },*/
      {
        cell: (row) => (
          row.statut != 'Traite' ? (
            <CButton
              color="success"
              variant="outline"
              shape="square"
              size="sm"
              style={{ width: 100,marginLeft:5 }}
              disabled={row.statut==='Valide'}
              onClick={() => {
                setSelectedRow(row);
                handleValidation(row.idDemande);
              }}
            >
              Validée
              <CIcon name="cilCheckAlt" style={{ marginLeft: 10 }} />
            </CButton>
          ) : null
        ),
        ignoreRowClick: true,
        button: true,
        export: false,
      },
     /* {
        cell: (row) => (
          row.statut === 'Accepte' ? (
            <CButton
              color="dark"
              variant="outline"
              shape="rounded-pill"
              size="sm"
              style={{ backgroundColor: "#00FFFF", width: 100, marginLeft: 5 }}
              onClick={() => {
                setSelectedRow(row);
                handleTraite(row.idDemande);
              }}
            >
              Traitée
              <CIcon name="cilCheckAlt" style={{ marginLeft: 10 }} />
            </CButton>
          ) : null
        ),
        ignoreRowClick: true,
        button: true,
        export: false,
      }*/
    ],
    []
  );
  const handleSubmit = (values) => {
    dispatch(listSoutienFilterAPI(values));

  };

  const toggleModalRefus = () => {
    setModalRefus(!modalRefus);
  };


  const formattedData = listDemande.map((listDemande) => ({
    idDemande: listDemande.demande.id,
    beneficiere:listDemande.demande.prenom+' '+listDemande.demande.nom,
    demande: listDemande.demande,
    cin:listDemande.demande.cin,
    type:listDemande.demande.type,
    duree :listDemande.duree,
    garanties: listDemande.garantiesList,
    aventage : listDemande.aventage,
    statut: listDemande.demande.statut,
    dateInscription: listDemande.demande.dateInscription,
  }));


  const handleSubmitRefus = (values) => {
    values.idDemande =selectedRow.idDemande;
    dispatch(refuserSoutientAPI(values)).then(() => {
      toast.warn('La demande a été rejetée !!!');
      setModalRefus(false);
      dispatch(listDemandeApi());
    }).catch(error => {
      toast.error('Une erreur est survenue lors de Refus ');
    });
  };
  const handleSubmitIncomplete = (values) => {
    values.idDemande =selectedRow.idDemande;
    dispatch(incompteteSoutienAPI(values)).then(() => {
      toast.warn('La demande a été Icompléte !!!');
      setModalIncmplete(false);
      dispatch(listDemandeApi());
    }).catch(error => {
      toast.error('Une erreur est survenue lors de Refus ');
    });
  };

  const toggleModalDeatils = () => {
    setModalDetails(!modalDetails);
  };

  const statut = [
    { value: 'En attente', libelle: 'En attente' },
    { value: 'Accepte', libelle: 'Accepte' },
    { value: 'Traite', libelle: 'Traite' },
    { value: 'Depose', libelle: 'Depose' },
    { value: 'Rejet', libelle: 'Rejet' },
  ];

  const getBadge = (status)=>{
    switch (status) {
      case 'Accepte': return 'warning'
      case 'Valide': return 'primary'
      case 'Depose': return 'primary'
      case 'En attente': return 'secondary'
      case 'Traite': return 'success';
      case 'Rejet': return 'danger'
      case 'Non Regulier': return 'danger'
      case 'Regulier': return 'success'
      default: return 'secondary'
    }
  }

  return (
    <>
      <CRow>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardBody>
              <ToastContainer />
              <Formik
                initialValues={{
                  id_annee: 25,
                  id_mois:null,
                  statut:"",
                  id_commune:null,
                  id_province:null,
                  id_region:null,
                  cin: "",
                }}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, setFieldValue }) => (
                  <CForm className=" g-3" onSubmit={handleSubmit}>

                    <CRow style={{ display: "flex", justifyContent: "center" }}>
                      <CCol xs="3">
                        <CFormGroup>
                          <TrSelect
                            name="id_annee"
                            icon="cil-paperclip"
                            libelle="Année "
                            valueWithLabel="false"
                            error={
                              props.errors.id_annee && props.touched.id_annee
                            }
                            //listOptions = {listAnnee}
                            customComponent={SelectInput}
                            placeholder="Selectionner..."
                          />
                        </CFormGroup>
                      </CCol>
                      {/*<CCol xs="3">
                        <CFormGroup>
                          <TrSelect
                            name="id_mois"
                            icon="cil-paperclip"
                            libelle="Mois "
                            valueWithLabel="false"
                            error={
                              props.errors.id_mois && props.touched.id_mois
                            }
                            listOptions = {listeMois}
                            customComponent={SelectInput}
                            placeholder="Selectionner..."
                          />
                        </CFormGroup>
                      </CCol>*/}
                      <CCol xs="3">
                        <CFormGroup>
                          <TrInput
                            name="cin"
                            icon="cil-paperclip"
                            libelle="N° CNIE"
                            inputType="text"
                            error={props.errors.cin && props.touched.cin}
                            customComponent={MyInput}
                          />
                          <ErrorMessage
                            name="cin"
                            component="div"
                            style={invalide}
                          />
                        </CFormGroup>
                      </CCol>

                      <CCol xs="3">
                        <CFormGroup>
                          <TrSelect
                            name="statut"
                            icon="cil-paperclip"
                            libelle="Statut"
                            listOptions={statut}
                            customComponent={SelectInput}
                            placeholder="Sélectionnez..."
                          />
                        </CFormGroup>
                      </CCol>
                    </CRow>

                    <div
                      className="form-actions"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 30,
                      }}
                    >
                      <CButton onClick={handleSubmit} color="success">
                        Chercher
                      </CButton>
                    </div>
                  </CForm>
                )}
              </Formik>

            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CCard>
        <CCardHeader>
              <span style={{ fontSize: 16, fontWeight: "500" }}>
                 Demande Assurance
              </span>
          <div className="card-header-actions">

          </div>
        </CCardHeader>

        <CCardBody>
          <CTabs activeTab={"correct"}
                 onActiveTabChange={(idx) => setActive(idx)}>
            <CNav variant="tabs">
              <CNavItem>
                <CNavLink data-tab="correct">
                  La liste des demandes
                </CNavLink>
              </CNavItem>
              <CNavItem>

              </CNavItem>

            </CNav>

            <CTabContent>
              <CTabPane data-tab="correct" style={{ marginTop: 20 }}>
                <BaseTableParams
                  data={formattedData}
                  columns={columns}
                />
              </CTabPane>
              <CTabPane data-tab="incorrect" style={{ marginTop: 20 }}>

              </CTabPane>
            </CTabContent>
          </CTabs>
        </CCardBody>
      </CCard>

      {modalDetails && selectedRow && (
        <CModal  style={{ width: 800,  }}
                 show={modalDetails} onClose={toggleModalDeatils}
        >
          <CModalHeader>
            <h5>Détails de la demande</h5>

            <CButton
              style={{ alignItems: "right", display: "flex" }}
              color="danger"
              onClick={() => {
                setModalDetails(false);
              }}
            >
              <CIcon name="cil-x"  />
            </CButton>
          </CModalHeader>
          <Form >
            <CModalBody style={{ textAlign: "center" }}>
              <CCard>
                <CCardHeader><h5>Information de bénéficiere</h5></CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol>
                      <strong>CIN  </strong> <CInput
                      type="text"
                      value={selectedRow.demande.cin}
                      disabled={true}
                    />
                    </CCol>
                    <CCol>
                      <strong>Prenom  </strong><CInput
                      type="text"
                      value={selectedRow.demande.prenom}
                      disabled={true}
                    />
                    </CCol>
                    <CCol>
                      <strong>Nom </strong><CInput
                      type="text"
                      value={selectedRow.demande.nom}
                      disabled={true}
                    />
                    </CCol>
                  </CRow>
                  <CRow style={{marginTop : 15}}>
                    <CCol>
                      <strong>Telephone  </strong><CInput
                      type="text"
                      value={selectedRow.demande.telephone}
                      disabled={true}
                    />
                    </CCol>
                    <CCol>
                      <strong>Email  </strong> <CInput
                      type="text"
                      value={selectedRow.demande.email}
                      disabled={true}
                    />
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>

              <CCard>
                <CCardHeader><h5>Assurance</h5></CCardHeader>
                <CCardBody>
                  <CRow style={{marginTop : 5}}>
                    <CCol>
                      <strong>Date effet  </strong><CInput
                      type="text"
                      value={selectedRow.demande.dateEffet}
                      disabled={true}
                    />
                    </CCol>
                    <CCol>
                      <strong>Duree de contrat </strong> <CInput
                      type="text"
                      value={selectedRow.duree}
                      disabled={true}
                    />
                    </CCol>
                    <CCol>
                      <strong>Aventage </strong> <CInput
                      type="text"
                      value={selectedRow.aventage}
                      disabled={true}
                    />
                    </CCol>
                  </CRow>
                  <CRow style={{marginTop : 15}}>
                    <CCol xs={4}>
                      <strong>Garnties </strong>
                      <div>
                        {selectedRow.garanties && selectedRow.garanties.length > 0 ? (
                          selectedRow.garanties.map((garantie, index) => (
                            <div key={index}>
                              <CInput
                                type="text"
                                value={garantie} // Remplacez "nom" par la propriété correspondant à l'information à afficher
                                disabled={true}
                              />
                            </div>
                          ))
                        ) : (
                          <CInput
                            type="text"
                            value="Aucune garantie disponible"
                            disabled={true}
                          />
                        )}
                      </div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
              <CCard>
                <CCardHeader><h5>Fichiers joints</h5></CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol xs="10">
                      <br />
                      <CDataTable
                        items={listeTypeFichierSaved}
                        fields={fields}

                        itemsPerPage={10}
                        pagination
                        noItemsViewSlot={
                          <h5
                            style={{
                              textAlign: "center",
                              paddingTop: "3rem",
                            }}
                          >
                            Pas d'information à afficher
                          </h5>
                        }
                        scopedSlots={{
                          action: (item) => (
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >

                              <CButton
                                variant="outline"
                                color="primary"
                                size="sm"
                                onClick={() => {
                                  const data = {
                                    ...item,
                                    idDemande: selectedRow.demande.id,          // Assurez-vous que idDemande est défini dans votre scope
                                    id_type_fichier: item.id  // Utilisation du libellé comme valeur pour id_type_fichier
                                  };

                                  dispatch(getFileAssuranceByDemandeAndType(data)).unwrap()
                                    .then((res) => {
                                      console.log("Réponse Blob reçue", res);
                                      if (!res) {
                                        console.error("Aucun fichier reçu");
                                        return;
                                      }

                                      const fileBlob = new Blob([res], { type: 'application/pdf' });

                                      // Créer une URL temporaire pour le Blob
                                      const fileUrl = URL.createObjectURL(fileBlob);

                                      // Ouvrir le fichier dans un nouvel onglet
                                      window.open(fileUrl, "_blank");

                                      // Révoquer l'URL après 5 minutes pour libérer la mémoire
                                      setTimeout(() => {
                                        URL.revokeObjectURL(fileUrl);
                                      }, 1000 * 60 * 5);
                                    })
                                    .catch((err) => {
                                      console.log("Erreur lors de la visualisation du fichier", err);
                                    });


                                }}
                                style={{ marginRight: 10 }}
                              >
                                Télécharger
                              </CButton>

                            </td>
                          ),
                        }}
                      />
                      <br />
                      <br />
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CModalBody>

          </Form>
        </CModal>
      )}

      {modalRefus && selectedRow && (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={selected}
          onSubmit={handleSubmitRefus}
        >
          {({ isSubmitting, handleSubmit, setSubmitting, values,errors  }) => (
            <CModal
              show={modalRefus} onClose={toggleModalRefus}
            >
              <CModalHeader>
                <h5>Demande refusée</h5>
                <CButton
                  style={{ alignItems: "right", display: "flex" }}
                  color="danger"
                  onClick={() => {
                    setModalRefus(false);
                  }}
                >
                  <CIcon name="cil-x"  />
                </CButton>
              </CModalHeader>
              <CModalBody>
                <CFormGroup row>
                  <CCol xs="5" style={{marginTop:25,marginLeft:10}}>
                    <CLabel>Motif de refus</CLabel>
                  </CCol>
                  <CCol xs="6">
                    <CFormGroup>
                      <TrInput
                        name="motifRefus"
                        icon="cil-paperclip"
                        // libelle="N° CNIE"
                        inputType="text"
                        error={props.errors.motifRefus && props.touched.motifRefus}
                        customComponent={MyInput}
                      />
                      <ErrorMessage
                        name="motifRefus"
                        component="div"
                        style={invalide}
                      />
                    </CFormGroup>
                  </CCol>

                </CFormGroup>
                <CFormGroup>
                  <CButton
                    color="primary"
                    //variant="outline"
                    shape="square"
                    size="sm"
                    style={{ width: 100,float: 'right' }}
                    onClick={handleSubmit}
                  >
                    Confirmée

                  </CButton>
                </CFormGroup>
              </CModalBody>
            </CModal>
          )}
        </Formik>
      )}
      {modalIncmplete && selectedRow && (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={selected}
          onSubmit={handleSubmitIncomplete}
        >
          {({ isSubmitting, handleSubmit, setSubmitting, values,errors  }) => (
            <CModal
              show={modalIncmplete} onClose={toggleModalRefus}
            >
              <CModalHeader>
                <h5>Demande Incomplète</h5>
                <CButton
                  style={{ alignItems: "right", display: "flex" }}
                  color="danger"
                  onClick={() => {
                    setModalIncmplete(false);
                  }}
                >
                  <CIcon name="cil-x"  />
                </CButton>
              </CModalHeader>
              <CModalBody>
                <CFormGroup row>
                  <CCol xs="5" style={{marginTop:25,marginLeft:10}}>
                    <CLabel>Commentaire</CLabel>
                  </CCol>
                  <CCol xs="6">
                    <CFormGroup>
                      <TrInput
                        name="motifRefus"
                        icon="cil-paperclip"
                        // libelle="N° CNIE"
                        inputType="text"
                        error={props.errors.motifRefus && props.touched.motifRefus}
                        customComponent={MyInput}
                      />
                      <ErrorMessage
                        name="motifRefus"
                        component="div"
                        style={invalide}
                      />
                    </CFormGroup>
                  </CCol>

                </CFormGroup>
                <CFormGroup>
                  <CButton
                    color="primary"
                    //variant="outline"
                    shape="square"
                    size="sm"
                    style={{ width: 100,float: 'right' }}
                    onClick={handleSubmit}
                  >
                    Confirmée

                  </CButton>
                </CFormGroup>
              </CModalBody>
            </CModal>
          )}
        </Formik>
      )}
    </>
  )

};

const ColonieForm = withFormik({

})(Colonies);

export default ColonieForm;
